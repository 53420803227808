import React, { useContext, useEffect, useState } from 'react';
import SEO from '../components/SEO';
import { Link } from 'gatsby';
import Spanify from '../components/Spanify';
import { LocaleContext } from '../components/Layout';
import { AppContext } from '../AppContext';
import './404.scss';

const NotFoundPage = () => {
  const { locale } = useContext(LocaleContext);
  const { setTheme } = useContext(AppContext);
  const [hoverAnimationActive, setHoverAnimationActive] = useState(false);

  useEffect(() => {
    setTheme('dark');
  }, []);
  return (
    <div className="not-found">
      <SEO title="404: Not found" lang={locale} />
      <h1>404</h1>
      <Link to={locale === 'en' ? '/' : '/es'}>
        <h2
          className={`rep-link ${
            hoverAnimationActive ? 'hover-animation-active' : ''
          }`}
          onMouseEnter={() => setHoverAnimationActive(true)}
        >
          <Spanify text="Back home" />
        </h2>
      </Link>
    </div>
  );
};

export default NotFoundPage;
